import { useAppSelector } from 'redux/hooks';
import { Box, Stack, Typography } from '@mui/material';
import { persistor } from 'redux/store';
import { useRouter } from 'next/router';
import { useEffect, useState, type FC } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import palette from 'components/common/style/color.module.scss';
import moment from 'moment';
import { useDispatch } from 'react-redux';
// import { clearHotelSearchHistory, type HotelSearchHistoryItemType } from 'redux/hotel-search-history-slice';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import { RoomPassengerType } from 'components/hotels/search-fields-hotels/search-fields-hotels.types';
import NextImage from '@travel-platform/commons/src/components/common/next-image';
import { clearHotelSearchHistory, HotelSearchHistoryItemType } from '@travel-platform/commons/src/redux/hotel-search-history-slice';
import { CmsLandingComponentsRecentSearch } from 'types';

const SearchHistory: FC<CmsLandingComponentsRecentSearch> = ({ type }) => {
    const { HotelSearchHistory } = useAppSelector(state => state.HotelSearchHistory);
    const [recentList, setRecentList] = useState<any[]>([]);

    useEffect(() => {
        if (type === 'Hotel') setRecentList(HotelSearchHistory);
    }, [type]);
    const dispatch = useDispatch();
    const languageJson = useLanguage();
    return (
        <PersistGate
            loading={null}
            persistor={persistor}>
            {recentList.length > 0 ? (
                <Stack
                    mt={4}
                    direction={{
                        xs: 'column',
                        md: 'row',
                    }}>
                    <Stack
                        direction={{
                            xs: 'row',
                            md: 'column',
                        }}
                        justifyContent={'space-between'}
                        mb={{ xs: 1.5, md: 0 }}
                        mr={{ xs: 0, md: 3 }}>
                        <Stack>
                            <Typography sx={{ typography: { xs: 'b1_bold', md: 't3_bold' }, whiteSpace: 'nowrap' }}>{languageJson.common.recent_hotel_searches}</Typography>
                        </Stack>
                        <Stack
                            onClick={() => dispatch(clearHotelSearchHistory())}
                            direction={'row'}
                            color={'error.main'}
                            sx={{ cursor: 'pointer' }}
                            alignItems={'center'}
                            justifyContent={'end'}>
                            <Box
                                component={'span'}
                                className='icon-trash'
                                mr={1}></Box>
                            <Typography sx={{ typography: { xs: 'b1_bold', md: 'b2_bold' } }}>{languageJson.common.delete}</Typography>
                        </Stack>
                    </Stack>
                    <Stack
                        direction={'row'}
                        spacing={2}
                        sx={{ overflowX: 'scroll', scrollbarWidth: 'none' }}>
                        {HotelSearchHistory.map((recent: HotelSearchHistoryItemType, index: number) => (
                            <RecentHotelSearchItem
                                key={index}
                                {...recent}
                            />
                        ))}
                    </Stack>
                </Stack>
            ) : (
                ''
            )}
        </PersistGate>
    );
};

export const RecentHotelSearchItem: FC<HotelSearchHistoryItemType> = ({ data, href, image }) => {
    const router = useRouter();
    const languageJson = useLanguage();
    const passengers = data.roomPassenger.reduce((sumAllPassengers: number, room: RoomPassengerType) => {
        sumAllPassengers = sumAllPassengers + room.passengers.adult.value + room.passengers.child.value;

        return sumAllPassengers;
    }, 0);
    // Process to add default image
    const processedImage = image || (data.hotelCity.group === 'city' ? '/location.svg' : '/house.svg');

    return (
        <Stack
            onClick={() => {
                router.push(href);
            }}
            direction={'row'}
            sx={{
                cursor: 'pointer',
                p: 1,
                alignItems: 'center',
                border: `1px solid ${palette.gray_light_40}`,
                borderRadius: 1,
                width: { xs: 180, md: 280 },
                height: { xs: 64, md: 80 },
            }}>
            <NextImage
                src={processedImage}
                alt={href}
                style={{ borderRadius: 5 }}
                width={{ xs: 50, md: 67 }}
                height={{ xs: 48, md: 64 }}
            />
            <Stack
                ml={1}
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                <Typography
                    sx={{
                        typography: { xs: 'b2_bold', md: 'c1_bold' },
                    }}>
                    {data.hotelCity.label}
                </Typography>
                <Typography
                    sx={{
                        typography: { xs: 'c3_regular', md: 'c2_regular' },
                        color: palette.gray_darker_60,
                    }}>
                    {moment(data.rangeDate.start).format('MMM Do')} -&nbsp;
                    {moment(data.rangeDate.end).format('MMM Do')}
                </Typography>
                <Typography sx={{ typography: { xs: 'c2_regular', md: 'c1_regular' } }}>
                    {passengers} &nbsp;
                    {passengers > 1 ? languageJson.common.passengers : languageJson.common.passenger}, &nbsp;
                    {data.roomPassenger.length} &nbsp;
                    {data.roomPassenger.length > 1 ? languageJson.common.rooms : languageJson.common.room}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default SearchHistory;
