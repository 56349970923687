import type { NextSeoProps } from 'next-seo/lib/types';
import type { GetTravelServiceResponseType } from './travel-services.types';

export enum AlertType {
    WARNING = 'WARNING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export enum ComponentType {
    ABOUT = 'ABOUT',
    ALERT = 'ALERT',
    STEPS = 'STEPS',
    TABLE = 'TABLE',
    FORM = 'FORM',
    FAQS = 'FAQS',
    AT_BOX = 'AT_BOX',
    TRAVEL_SERVICE_PLAN = 'TRAVEL_SERVICE_PLAN',
    PLAN_PRICING_SELECT_BOX = 'PLAN_PRICING_SELECT_BOX',
    MARKETING_BANNER = 'MARKETING_BANNER',
    CAR_PLANS = 'CAR_PLANS',
    INCLUSION_CART = 'INCLUSION_CART',
    CTA = 'CTA',
    TOP_ACTIVITY = 'TOP_ACTIVITY',
    TOP_ATTRACTION = 'TOP_ATTRACTION',
    COUNTRY_LIST = 'COUNTRY_LIST',
    App_Install_Banner = 'App_Install_Banner',
    Recent_Search = 'Recent_Search',
    Feature_Tiles = 'Feature_Tiles',
    Craft_Tour_CTA = 'Craft_Tour_CTA',
    Most_Booked_Flights = 'Most_Booked_Flights',
    Most_Visited_City = 'Most_Visited_City',
    DEPARTURE_SLIDER = 'DEPARTURE_SLIDER',
    SUGGESTED_TOUR_SLIDER = 'SUGGESTED_TOUR_SLIDER',
    SLIDER_CARD_COMPONENT = 'SLIDER_CARD_COMPONENT',
    RECENT_SEARCH_NEW_DESIGN = 'RECENT_SEARCH_NEW_DESIGN',
}

export type CmsLandingThumbnail = {
    src: string;
    width: number;
    height: number;
    alt: string;
    title: string;
};
export type CmsLandingSeo = NextSeoProps;

export type wrapperCardSliderType = {
    title: string;
    subTitle?: string;
    categorizeProperty?: string;
    slidesPerView?: number;
    queryParams: object;
    type: 'Hotel' | 'Tour' | 'other';
    id: number;
};

export type CmsLandingComponents = {
    type: ComponentType;
    priority: number;
    contactForm: boolean;
    about: CmsLandingComponentsAbout;
    alert: CmsLandingComponentsAlert;
    step: CmsLandingComponentsSteps;
    table: CmsLandingComponentsTable;
    attentionBox: CmsLandingComponentsAttentionBox;
    travel_service_plan: CmsLandingComponentsTravelServicePlan;
    plan_select_box: CmsLandingComponentsPlanSelectBox;
    faqs: CmsLandingComponentsFaqs[];
    formActivity: boolean;
    marketing_banner: CmsLandingComponentsMarketingBanner;
    car_plans: CmsLandingComponentsCarRentalPlan[];
    inclusion_carts: CmsLandingInclusionCard[];
    slider_card_component: wrapperCardSliderType;
    recent_search_new_design: CmsLandingComponentsRecentSearch;
};
export type CmsLandingComponentsAbout = {
    title: string;
    description: string;
};
export type CmsLandingComponentsAlert = {
    type: AlertType;
    title: string;
    description: string;
};
export type CmsLandingComponentsStepsItems = {
    id: number;
    title: string;
    description: string;
};
export type CmsLandingComponentsSteps = {
    title: string;
    description: string;
    landing_step_items: CmsLandingComponentsStepsItems[];
};
export type CmsLandingComponentsTable = {
    title: string;
    description: string;
    IsFirstHeading: boolean;
    items: Record<string, any>[];
};
export type CmsLandingComponentsAttentionBoxItems = {
    id: number;
    title: string;
    description: string;
};
export type CmsLandingComponentsAttentionBox = {
    title: string;
    description: string;
    items: CmsLandingComponentsAttentionBoxItems[];
};
export type CmsLandingComponentsTravelServicePlan = {
    id: number;
    services: GetTravelServiceResponseType[];
    title: string;
    landingTravelServiceId: number;
    landing_travel_service_plan_items: CmsLandingComponentsTravelServicePlanItems[];
};
export type CmsLandingComponentsTravelServicePlanItems = {
    id: number;
    title: string;
    description: string;
    button_text: string;
    travel_service_variant_id: number;
};
export type CmsLandingComponentsPlanSelectBox = {
    name: string;
    title: string;
    plan_select_box_items: CmsLandingComponentsPlanSelectBoxItems[];
};
export type CmsLandingComponentsRecentSearch = {
    type: 'Hotel' | 'Villa';
};
export type CmsLandingComponentsPlanSelectBoxItems = {
    plan_name: string;
    value: number;
};
export type CmsLandingComponentsFaqs = {
    id: number;
    question: string;
    answer: string;
};
export type CmsLandings = {
    id: number;
    slug: string;
    title: string;
    description: string;
    shortDesc: string;
    has_travel_service_module: boolean;
    travel_service_id: number;
    thumbnail: CmsLandingThumbnail;
    seo: CmsLandingSeo;
    components: CmsLandingComponents[];
};

export type CmsGetLandingDetailResponse = {
    data: CmsLandings[];
    meta: {
        pagination: {
            page: number;
            pageSize: number;
            pageCount: number;
            total: number;
        };
    };
};

// Car Rental
export type CmsLandingComponentsCarRentalPlan = {
    id: string;
    image: CmsImage;
    title: string;
    description: string;
    start_year: string;
    end_year: string;
    ac: boolean;
    gear_type: 'automat' | 'manual';
    doors: number;
    seats: number;
    luggages: number;
    car_prices: carPriceType[];
    deposit: string;
    insurances: (insuranceType & { travel_service_variant_id: string; tag: string })[];
};
export type carPriceType = {
    from_num_day: string;
    to_num_day: string;
    price: string;
};
export type insuranceType = {
    title: string;
    description: string;
    id: number;
    insurance_inclusion: {
        id: number;
        title: string;
        is_included: boolean;
    }[];
};
export type CmsLandingInclusionCard = {
    id: number;
    title: string;
    description: string;
    inclusions: {
        id: number;
        is_included: boolean;
        title: string;
    }[];
};
export type CmsImage = {
    alternativeText: string | null;
    caption: null | string;
    height: number;
    id: number;
    name: string;
    url: string;
    width: number;
};

export type CmsLandingComponentsTopActivity = {
    title: string;
    items: CmsLandingComponentsSliderItem[];
};
export type CmsLandingComponentsSliderItem = {
    id: number;
    title: string;
    description: string;
    imageUrl: string;
    url: string;
};
export type CmsLandingComponentsMarketingBanner = {
    items: CmsLandingComponentsMarketingBannerItem[];
};
export type CmsLandingComponentsMarketingBannerItem = {
    id: number;
    imageUrl: string;
    url: string;
};
export type CmsLandingComponentsCountryList = {
    title: string;
    items: CmsLandingComponentsSliderItem[];
};
export type CmsLandingComponentsCountryListItem = {
    id: number;
    title: string;
    description: string;
    imageUrl: string;
    url: string;
};
export type CmsCountries = {
    id: number;
    title: string;
    code: string;
    subTitle: string;
    recommended: boolean;
    icon: string;
    url: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    locale: string;
};
export type CmsGetCountriesResponse = {
    data: CmsCountries[];
    meta: {
        pagination: {
            page: number;
            pageSize: number;
            pageCount: number;
            total: number;
        };
    };
};
