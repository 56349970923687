import { Box, Stack, Typography } from '@mui/material';
import { AlertBox, AttentionBox, FaqList, ServiceStepList } from '@travel-platform/commons/src';
// import LandingLayout from 'components/common/layouts/landing-layout';
import { setCounties, setLandings } from '@travel-platform/commons/src/redux/cms-slice';
import CraftTourCTA from 'components/common/craft-tour-cta';
import LandingFeatureTiles from 'components/common/landing-feature-tiles';
import { DepartureTour } from 'components/landing/components/departure-tour/departure-tour';
import { MarketingBanner } from 'components/landing/components/marketing-banner';
import MostBookedFlightsSection from 'components/landing/components/most-booked-flights-section/most-booked-flights-section';
import MostVisitedCitySection from 'components/landing/components/most-visited-city-section/most-visited-city-section';
import RecentSearchSection from 'components/landing/components/recent-search-section/recent-search-section';
// import { RelatedTour } from 'components/landing/components/related-tour/related-tour';
import { TourStyleContentTopActivities } from 'components/landing/components/top-activities';
import { TourStyleContentTopAttractions } from 'components/landing/components/top-attractions';
import TouristMagazine from 'components/landing/components/tourist-magazine/tourist-magazine';
import React, { FC, useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { useRouter } from 'next/router';
import { ErrorBoundary } from '@sentry/nextjs';
import { ServiceModule } from 'components/landing/components/service-component';
import { setServices } from '@travel-platform/commons/src/redux/travelers-slice';
import TitleDescription from 'components/common/title-description';
import LandingPageTravelServicePlan from 'components/landing/components/travel-service-plan';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from 'redux/store';
import dynamic from 'next/dynamic';
import { LandingPageProps } from 'pages/[[...slug]]';
import { ComponentType } from 'types/cms.types';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import SliderWrapper from 'components/common/slider-wrapper';
import RecentSearch from 'components/common/recent-search';

const RelatedTour = dynamic(() => import('components/landing/components/related-tour/related-tour').then(tour => tour.RelatedTour), {
    ssr: false,
});
const TouristDestinationsForTravel = dynamic(() => import('components/landing/components/tourist-destinations-for-travel/tourist-destinations-for-travel'), {
    ssr: false,
});
const InstallAppBanner = dynamic(() => import('components/common/install-app'), {
    ssr: false,
});
const HeroSection = dynamic(() => import('components/common/hero-section'), {
    ssr: false,
});

const LandingPageComponents: FC<LandingPageProps> = ({ LandingList, landingData, tours, posts, slug, ...rest }) => {
    // Destructure props
    const { title, description, thumbnail, has_travel_service_module, components, travel_service_id, country, seo } = landingData;

    // Use Hooks
    const { common } = useLanguage();
    const dispatch = useAppDispatch();
    const router = useRouter();

    const serviceSelected = rest.localServices.find(({ id }) => id === travel_service_id);

    useEffect(() => {
        dispatch(setLandings(LandingList));
        dispatch(setServices(rest.services));
        dispatch(setCounties(rest.countries));
    }, [router.locale]);

    const sortComp = components.sort((a, b) => a.priority - b.priority);

    return (
        <>
            <HeroSection
                title={title}
                description={description}
                thumbnail={thumbnail}
                country={country}
                has_travel_service_module={has_travel_service_module}
                isFoundSelectedService={!!serviceSelected}
                localServices={rest.localServices}
                countries={rest.countries}
            />
            <Stack spacing={4}>
                {has_travel_service_module && serviceSelected && (
                    <Box>
                        <ServiceModule
                            services={rest.localServices}
                            serviceSelected={serviceSelected}
                        />
                    </Box>
                )}
                {sortComp.map((component, index) => (
                    <React.Fragment key={index}>
                        {component.type === ComponentType.MARKETING_BANNER && <MarketingBanner {...component.marketing_banner} />}
                        {component.type === ComponentType.SLIDER_CARD_COMPONENT && <SliderWrapper {...component.slider_card_component} />}
                        {component.type === ComponentType.RECENT_SEARCH_NEW_DESIGN && <RecentSearch {...component.recent_search_new_design} />}

                        {component.type === ComponentType.ABOUT && (
                            <TitleDescription
                                title={component.about?.title}
                                description={component.about?.description}
                            />
                        )}
                        {component.type === ComponentType.SUGGESTED_TOUR_SLIDER && !!tours && (tours?.length as number) > 0 && (
                            <Stack spacing={{ xs: 2, md: 4 }}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: '20px',
                                            md: '28px',
                                        },
                                        lineHeight: {
                                            xs: '24px',
                                            md: '42px',
                                        },
                                        fontWeight: 700,
                                    }}>
                                    {common?.suggested_tours}
                                </Typography>
                                <RelatedTour tours={tours} />
                            </Stack>
                        )}
                        {component.type === ComponentType.DEPARTURE_SLIDER && !!rest.departure && (rest.departure?.length as number) > 0 && (
                            <Stack spacing={{ xs: 2, md: 4 }}>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: '20px',
                                            md: '28px',
                                        },
                                        lineHeight: {
                                            xs: '24px',
                                            md: '42px',
                                        },
                                        fontWeight: 700,
                                    }}>
                                    {common.join_jur_group_tours}
                                </Typography>
                                <DepartureTour departure={rest.departure} />
                            </Stack>
                        )}
                        {component.type === ComponentType.TRAVEL_SERVICE_PLAN && (
                            <LandingPageTravelServicePlan
                                {...component.travel_service_plan}
                                services={rest.localServices}
                                landingTravelServiceId={landingData.travel_service_id}
                            />
                        )}
                        {/* {component.type === 'PLAN_PRICING_SELECT_BOX' && <LandingPagePlanSelectBox {...component.plan_select_box} />} */}
                        {component.type === ComponentType.ALERT && <AlertBox {...component.alert} />}
                        {component.type === ComponentType.STEPS && <ServiceStepList {...component.step} />}
                        {/* {component.type === 'TABLE' && <LandingPageTable {...component.table} />} */}
                        {/* {component.type === 'FORM' && component.formActivity && <LandingPageForm />} */}
                        {component.type === ComponentType.AT_BOX && <AttentionBox {...component.attentionBox} />}
                        {component.type === ComponentType.FAQS && (
                            <FaqList
                                title={common.faq}
                                faqs={component.faqs}
                            />
                        )}
                        {component.type === ComponentType.TOP_ATTRACTION && (
                            <TourStyleContentTopAttractions
                                title={component.top_attraction?.title}
                                items={component.top_attraction?.items}
                            />
                        )}
                        {component.type === ComponentType.TOP_ACTIVITY && (
                            <Stack spacing={{ xs: 2, md: 4 }}>
                                <TourStyleContentTopActivities
                                    title={component.top_activity?.title}
                                    items={component.top_activity?.items}
                                />
                            </Stack>
                        )}
                        <PersistGate
                            loading={null}
                            persistor={persistor}>
                            {component.type === ComponentType.COUNTRY_LIST && (
                                <TouristDestinationsForTravel
                                    title={component.country_list?.title}
                                    items={component.country_list?.items}
                                />
                            )}
                        </PersistGate>
                        <PersistGate
                            loading={null}
                            persistor={persistor}>
                            {component.type === ComponentType.Recent_Search && <RecentSearchSection />}
                        </PersistGate>
                        {component.type === ComponentType.App_Install_Banner && <InstallAppBanner />}
                        {component.type === ComponentType.Most_Visited_City && <MostVisitedCitySection />}
                        {component.type === ComponentType.Most_Booked_Flights && <MostBookedFlightsSection />}
                        {component.type === ComponentType.Feature_Tiles && <LandingFeatureTiles />}
                        {component.type === ComponentType.Craft_Tour_CTA && <CraftTourCTA />}
                    </React.Fragment>
                ))}
                <ErrorBoundary
                    fallback={
                        <Typography
                            textAlign={'center'}
                            variant='b2_bold'>
                            Blog is not available.
                        </Typography>
                    }>
                    {!!posts && posts.length > 0 && <TouristMagazine posts={posts} />}
                </ErrorBoundary>
            </Stack>
        </>
    );
};
export default LandingPageComponents;
