import { PassengerType, SupportedCurrency } from '../flights/flight.types';

export type GetHotelDataResponse = {
    id: number;
    lastUpdate: string;
    name: string;
    location: {
        address: string;
        latitude: string;
        longitude: string;
        country: {
            code: string;
            name: string;
        };
        city: {
            id: number;
            name: string;
        };
    };
    rating?: number; //stars
    reviewScore?: number;
    contactInfo: {
        email: string;
        url: string;
        fax: string;
        phone: string;
    };
    description: string;
    rules: {
        instructions?: string;
        specialInstructions?: string;
        beginTime?: string;
        endTime?: string;
        minAge?: string;
        checkOutTime?: string;
    };
    accommodation: Accommodation; //hotelType
    guestRating: {
        overall: number;
        count: number;
        details: {
            cleanliness: number;
            comfort: number;
            service: number;
            condition: number;
            neighborhood: number;
            location: number;
            value: number;
            amenities: number;
        };
    };
    nearLocations: {
        placeName: string;
        distance: string; //"0.2 miles"
        category: string;
        subcategory: string;
    }[];
    amenities: {
        group: string;
        amenities: string[];
    }[];
    facilities: {
        group: string;
        facilities: string[];
    }[];
    images: { link: string; thumbnailLink: string }[];
};

export enum Accommodation {
    Other = 'Other',
    Camping = 'Camping',
    Rural = 'Rural',
    Apartments = 'Apartments',
    GuestAccommodation = 'GuestAccommodation',
    Hostels = 'Hostels',
    Hotels = 'Hotels',
    Motels = 'Motels',
    Resorts = 'Resorts',
    Residences = 'Residences',
    BedAndBreakfasts = 'BedAndBreakfasts',
    Ryokans = 'Ryokans',
    FarmStays = 'FarmStays',
    HolidayParks = 'HolidayParks',
    Villas = 'Villas',
    Campsites = 'Campsites',
    Boats = 'Boats',
    GuestHouses = 'GuestHouses',
    Uncertain = 'Uncertain',
    Inns = 'Inns',
    Aparthotels = 'Aparthotels',
    HolidayHomes = 'HolidayHomes',
    Lodges = 'Lodges',
    Homestays = 'Homestays',
    CountryHouses = 'CountryHouses',
    LuxuryTents = 'LuxuryTents',
    CapsuleHotels = 'CapsuleHotels',
    LoveHotels = 'LoveHotels',
    Riads = 'Riads',
    Chalets = 'Chalets',
    Condos = 'Condos',
    Cottages = 'Cottages',
    EconomyHotels = 'EconomyHotels',
    Gites = 'Gites',
    HealthResorts = 'HealthResorts',
    Cruises = 'Cruises',
}
export type RoomType = {
    name: string;
    adultCount: number;
    children: string[] | null;
    mealType: string;
    bedGroups: string | null;
    sharinBedding: boolean;
    earlyCheckingPrice: {
        dateTime: string;
        price: number;
    } | null;
    lateCheckoutPrice: {
        dateTime: string;
        price: number;
    } | null;
};
export type SearchHotelNamesRequest = {
    query: string;
};
export type SearchHotelNamesResponse = {
    id: number;
    hotelNames: {
        language: string;
        value: string;
    }[];
    city: {
        code: string;
        cityNames: {
            language: string;
            value: string;
        }[];
        country: {
            code: string;
            countryName: any[];
        };
    };
};
export type SearchCityNamesRequest = {
    query: string;
};
export type SearchCityNamesResponse = {
    id: number;
    cityNames: {
        language: string;
        value: string;
    }[];
    country: {
        countryNames: {
            language: string;
            value: string;
        }[];
    };
};
export type HotelDataType = {
    hotelId: number;
    rooms: RoomType[];
    isRefundable: boolean;
    refoundType: string;
    cancellationPolicies: {
        fromDate: string;
        amount: number;
    }[];
    amenities?: string[];
    availableRoom: number | null;
    isBlockout: boolean;
    isResrvationOffline: boolean;
};
export type SearchAvailableHotelInACityRequest = {
    checkIn: string;
    checkOut: string;
    cityId: number;
    rooms: {
        adultCount: number;
        children: number[];
    }[];
};
export type SearchAvailableHotelInACityResponse = {
    citySearchToken: string;
    expireAt?: string;
};
export type SearchHotelRoomsAvailableRequest = {
    checkIn: string;
    checkOut: string;
    hotelId: number;
    rooms: {
        adultCount: number;
        children: number[];
    }[];
};
export type SearchHotelRoomsAvailableResponse = {
    hotelSearchToken: string;
};
export type GetHotelSearchResultRequest = {
    hotelSearchToken: string;
};
export type GetHotelSearchResultResponse = {
    offerId: string;
    hotel: HotelDataType;
    payablePrice: {
        basePrice: number;
        discount: number;
        totalPrice: number;
    };
};
export type ValidateOfferRequest = {
    offerId: string;
};
export type ValidateOfferResponse = {
    status: boolean;
    offer: {
        checkIn: string;
        checkOut: string;
        hotel: HotelDataType;
        payablePrice: {
            basePrice: number;
            discount: number;
            totalPrice: number;
        };
    };
};
export type CreateReservationRequest = {
    offerId: string;
    passengerRooms: {
        passengers: {
            firstName: string;
            lastName: string;
            passengerType: string;
            age?: number;
        }[];
    }[];
    contactEmail?: string;
    contactMobile: string;
};
export type CreateReservationResponse = {
    id: number;
};
export type GetReservationByIdRequest = {
    ids: string[];
};

export type ReservedRoom = {
    id?: number;
    name: string;
    mealType: string;
    adultCount: number;
    children: number[] | null;
    sharingBedding: boolean;
    bedGroups: string;
    earlyCheckingPrice: {
        dateTime: string;
        price: number;
    };
    lateCheckoutPrice: {
        dateTime: string;
        price: number;
    };
    passengers: Passenger[];
};
export type Passenger = {
    id?: number;
    firstName: string;
    lastName: string;
    age?: number;
    passengerType: PassengerType;
    nationalId?: string;
};

export type ReservationMeta = {
    amenities?: string[];
    remarks?: string[];
    cancellation_policies?: any[];
    hotelConfirmationNo?: string;
    vatNumber?: string;
    rooms: ReservedRoom[];
    error?: any;
};
export type GetReservationByIdResponse = {
    id: number;
    contactEmail: string;
    contactMobile: string;
    status: string;
    hotelId: number;
    hotel: {
        id: number;
        cityId: number;
        priority: number;
        hotelNames: {
            language: string;
            value: string;
        }[];
    };
    provider?: {
        id: number;
        name: string;
        profitPercentage: number;
        type: {
            id: number;
            type: string;
        };
    };
    providerDetail?: {
        id: number;
        name: string;
        price: {
            amount: number;
            currency: SupportedCurrency;
        };
        typeId: number;
        reference: string;
    } | null;
    totalPrice: number;
    discount: number;
    meta: ReservationMeta;
    createdAt: string;
    updatedAt: string;
    token: string;
};
export type GetReservationByTokenRequest = {
    jwtId: string;
};
export type GetReservationByTokenResponse = {
    checkIn: string;
    checkOut: string;
    id: number;
    contactEmail: string;
    contactMobile: string;
    status: string;
    hotelId: number;
    hotel: {
        id: number;
        cityId: number;
        priority: number;
        hotelNames: {
            language: string;
            value: string;
        }[];
    };
    totalPrice: number;
    discount: number;
    meta: ReservationMeta;
    createdAt: string;
    updatedAt: string;
};
export enum SortTypeCitySearchResult {
    ASC = 'ASC',
    DESC = 'DESC',
}
export enum SortPropertyCitySearchResult {
    RATING = 'RATING',
    PRICE = 'PRICE',
    REVIEWSCORE = 'REVIEW_SCORE',
}
export type GetCitySearchResultRequest = {
    citySearchToken: string;
    page: number;
    perPage: number;
    accommodationType?: Accommodation[];
    facilities?: string[];
    priceMin?: number;
    priceMax?: number;
    starRating?: Array<number | string>;
    foodType?: string[];
    sortType?: SortTypeCitySearchResult;
    sortProperty?: SortPropertyCitySearchResult;
};
export type HotelInfo = {
    hotelId: number;
    lastUpdate: string;
    name: string;
    location: {
        address: string;
        latitude: string;
        longitude: string;
        country: {
            code: string;
            name: string;
        };
        city: {
            id: number;
            name: string;
        };
    };
    rating?: number; //stars
    reviewScore?: number;
    contactInfo: {
        email: string;
        url: string;
        fax: string;
        phone: string;
    };
    description: string;
    rules: {
        instructions?: string;
        specialInstructions?: string;
        beginTime?: string;
        endTime?: string;
        minAge?: string;
        checkOutTime?: string;
    };
    accommodation: Accommodation | null; //hotelType
    guestRating: {
        overall: number;
        count: number;
        details: {
            cleanliness: number;
            comfort: number;
            service: number;
            condition: number;
            neighborhood: number;
            location: number;
            value: number;
            amenities: number;
        };
    };
    nearLocations: {
        placeName: string;
        distance: string; //"0.2 miles"
        category: string;
        subcategory: string;
    }[];
    amenities: {
        group: string;
        amenities: string[];
    }[];
    facilities: {
        group: string;
        facilities: string[];
    }[];
    images: { link: string; thumbnailLink: string }[];
    tags: {
        color: string;
        id: number;
        language: string;
        title: string;
    }[];
    shortDescription: string;
    faqs: {
        question: string;
        answer: string;
    }[];
    defaultStartPrice: number;
    defaultShowingDiscountPtc: number;
    cityName: string;
};
export type ResultSearchHotelDataType = {
    hotel: HotelDataType;
    payablePrice: {
        basePrice: number;
        discount: number;
        totalPrice: number;
    };
    providerDetail?: {
        amount: number;
        currency: SupportedCurrency;
        name: string;
        type: string;
    };
    hotelInfo?: HotelInfo;
};
export type GetCitySearchResultResponse = {
    result: ResultSearchHotelDataType[];
    count: number;
    page: number;
    perPage: number;
};
export type GetHotelInfoRequest = {
    hotelId: number;
    locale: string;
};
export type GetHotelInfoResponse = HotelInfo;

export type GetCitySearchFiltersRequest = {
    citySearchToken: string;
};
export type GetCitySearchFiltersResponse = {
    filters: {
        foodType?: string[];
        starRating?: Array<number | string>;
        price?: {
            min: number | null;
            max: number | null;
        };
        accommodationType?: Accommodation[];
        facilities?: string[];
        sortType?: SortTypeCitySearchResult;
        sortProperty?: SortPropertyCitySearchResult;
    };
};
export type GetHotelProviderTypesResponse = string[];

export type GetAllHotelProvidersRequest = {
    page: number;
    perPage: number;
};
export type GetAllHotelProvidersResponse = {
    id: 1;
    name: string;
    type: string;
    profitPercentage: number;

    config: {
        [key: string]: any;
    };
    isActive: boolean;

    storeId: number;
};

export type UpdateHotelProviderRequest = {
    profitPercentage: number;
    name: string;
    type: string;
    config: {
        [key: string]: any;
    };
    isActive: boolean;
};
export type UpdateHotelProviderResponse = {
    id: number;
    name: string;
    type: string;
    profitPercentage: number;
    config: {
        [key: string]: any;
    };
    isActive: boolean;
};
export type UpdatePlacesRequest = {
    countryCode: string;
};
export type RoomsFiltersType = {
    mealType: Array<string>;
    isRefoundable?: boolean;
};

export type GetHotelInfoListRequest = {
    sort: string;
    filter: { property: string; value: string | boolean; rule: string }[];
    page: number;
    perPage: number;
    locale: string;
};

export type GetHotelInfoListResponse = {
    result: HotelInfo[];
    total: number;
    page: number;
    perPage: number;
};
