import type { ButtonSliderType, SwiperSliderProps } from './swiper-slider.types';
import { Swiper } from 'swiper/react';
import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';

const buttonNavigation = {
    p: 1.5,
    width: {
        xs: '40px',
        md: '48px',
    },
    height: {
        xs: '40px',
        md: '48px',
    },
    minWidth: 0,
    borderRadius: '50px',
    '& .MuiButton-endIcon': {
        m: 0,
    },
};
const SwiperSlider = React.forwardRef<SwiperButtonRef, SwiperSliderProps>((props, ref) => {
    // Destructure Props
    const { children, spaceBetween, slidesPerView, breakpoints, loop, button, isPrevNext = true }: SwiperSliderProps = props;

    const language = useLanguage();

    // Reference
    const swiperRef = useRef<any>(null);

    React.useImperativeHandle(ref, () => ({
        next() {
            swiperRef.current?.swiper.slideNext();
        },
        prev() {
            swiperRef.current?.swiper.slidePrev();
        },
    }));

    function ButtonSlider(props: ButtonSliderType) {
        const { icon, navigation } = props;
        return (
            <Button
                size='large'
                variant='outlined'
                color='primary'
                sx={buttonNavigation}
                onClick={() => {
                    navigation === 'prev' ? swiperRef.current?.swiper.slidePrev() : swiperRef.current?.swiper.slideNext();
                }}
                {...props}
                endIcon={
                    <Box
                        fontSize={{
                            xs: 20,
                            md: 28,
                        }}
                        component='span'
                        className={icon}></Box>
                }></Button>
        );
    }

    return (
        <Stack gap={6}>
            <Swiper
                slidesPerView={slidesPerView || 1}
                spaceBetween={spaceBetween || 24}
                ref={swiperRef}
                breakpoints={
                    breakpoints || {
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        600: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        960: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                        1280: {
                            slidesPerView: 4,
                            spaceBetween: 40,
                        },
                    }
                }
                autoplay={{
                    delay: 5000,
                }}
                dir={language.direction}
                key={language.direction}
                speed={1000}
                loop={loop || false}
                style={{
                    width: '100%',
                    alignItems: 'center',
                }}>
                {children}
            </Swiper>
            {(isPrevNext || !!button) && (
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'>
                    {isPrevNext && (
                        <Stack
                            direction='row'
                            gap={3}>
                            <ButtonSlider
                                icon={language.direction === 'ltr' ? 'icon-chevron-light-left' : 'icon-chevron-light-right'}
                                navigation='prev'
                            />
                            <ButtonSlider
                                icon={language.direction === 'ltr' ? 'icon-chevron-light-right' : 'icon-chevron-light-left'}
                                navigation='next'
                            />
                        </Stack>
                    )}

                    {button && (
                        <a
                            target='_blank'
                            rel='noreferrer'
                            href={button.link}>
                            <Button
                                size='large'
                                variant='contained'
                                color='primary'
                                sx={{
                                    px: 1.5,
                                    borderRadius: '50px',
                                    '& .MuiButton-endIcon span': {
                                        fontSize: {
                                            xs: 12,
                                            md: 20,
                                        },
                                    },
                                }}
                                endIcon={
                                    <Box
                                        component='span'
                                        className={language.direction === 'ltr' ? 'icon-chevron-light-right' : 'icon-chevron-light-left'}></Box>
                                }>
                                <Typography
                                    component='span'
                                    sx={{
                                        typography: {
                                            xs: 'c1_bold',
                                            md: 'b2_bold',
                                        },
                                    }}>
                                    {button.label}
                                </Typography>
                            </Button>
                        </a>
                    )}
                </Stack>
            )}
        </Stack>
    );
});

SwiperSlider.displayName = 'SwiperSlider';
export default SwiperSlider;
