import React, { useEffect } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useAppSelector } from '../../../../redux/hooks';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { addServiceCart, EmptyServiceCartObject } from '@travel-platform/commons/src/redux/cart-slice';
import { GetTravelServiceResponseType } from 'types';
import { TravelServiceInputType } from 'types/travel-services.types';
import { TravelPackContinueButton } from './travel-pack-continue-button';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import { v4 as uuidv4 } from 'uuid';
import dynamic from 'next/dynamic';

const TravelersServicesCardVariant = dynamic(() => import('./service-module-box-inputs'), {
    ssr: false,
});

type LandingServiceModuleCardVariantProps = {
    service: GetTravelServiceResponseType;
};

export const LandingServiceModuleCardVariant = (props: LandingServiceModuleCardVariantProps) => {
    // Spread Props
    const { id, name, componentOptions, travelServiceVariants, variantFields, title } = props.service;

    // Use Hooks
    const languageJson = useLanguage();
    const router = useRouter();

    const { ServiceCart } = useAppSelector(state => state.cart);
    const thisServiceCartItems = ServiceCart.filter(item => item.travelServiceId === id);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!thisServiceCartItems.length) {
            addEmptyVariant();
        }
    }, [ServiceCart, router.asPath]);

    const addEmptyVariant = () => {
        dispatch(
            addServiceCart({
                ...EmptyServiceCartObject,
                fakeId: uuidv4(),
                travelServiceId: id,
            })
        );
    };

    // Check Special Service
    const isSpecial: boolean = componentOptions.isFeatured;

    // Constant
    const isNumberInput: boolean = variantFields.some(variant => variant.type === TravelServiceInputType.NUMBER_INPUT);

    const isVariantsCapabilityCompleted: boolean = isNumberInput ? !!thisServiceCartItems && thisServiceCartItems.length < 5 : !!travelServiceVariants?.length && travelServiceVariants?.length > 0 && thisServiceCartItems.length !== travelServiceVariants?.length;

    const buttonText: string = React.useMemo((): string => {
        let text: string = '';

        if (!thisServiceCartItems) return languageJson.common.add_to_pack;

        switch (name) {
            case 'iran-visa':
                text = languageJson.common.add_nationality;
                break;
            default:
                text = languageJson.common.add_another;
                break;
        }
        return text;
    }, [name]);

    if (!variantFields) return <></>;

    return (
        <Stack
            gap={3}
            p={0}>
            {thisServiceCartItems.map(variantItem => (
                <>
                    <TravelersServicesCardVariant
                        {...variantItem}
                        key={variantItem.id}
                        totalVariantsLength={ServiceCart?.filter(variant => variant.travelServiceId === id).length}
                        travelServiceVariants={travelServiceVariants!}
                        variantFields={variantFields}
                        serviceTitle={title}
                    />
                </>
            ))}
            <Stack
                flexWrap='wrap'
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                rowGap={2}>
                <Button
                    disabled={!isVariantsCapabilityCompleted}
                    onClick={() => {
                        isVariantsCapabilityCompleted && addEmptyVariant();
                    }}
                    color={isSpecial ? 'secondary' : 'primary'}
                    size='large'
                    variant='outlined'>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        spacing={{
                            xs: 0.5,
                            md: '6px',
                        }}>
                        <Box
                            component='span'
                            className='icon-add'
                            fontSize={16}></Box>
                        <Typography variant='c1_bold'>{buttonText}</Typography>
                    </Stack>
                </Button>
                <Box>
                    <TravelPackContinueButton
                        href='/travel-pack'
                        size='small'
                    />
                </Box>
            </Stack>
        </Stack>
    );
};
