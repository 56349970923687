import type { DocUploadResponse, GetDebitCardTopUpResponseType, GetTravelPackInfoResponseType, GetTravelPackOrTopUpRequestType, GetTravelPackResponseType } from './travel-service.types';
import { CancelToken } from 'axios';
import { Api, Method } from '@travel-platform/commons/src/services/api';

import type {
    CalculatePackDiscountRequestType,
    CalculatePackDiscountResponseType,
    CreateTravelPackProductRequest,
    CreateTravelPackProductResponse,
    GetTravelPackProductResponse,
    GetTravelServiceRequestType,
    GetTravelServiceResponseType,
    RequestDataTravelPackFormRequest,
    RequestDataTravelPackFormResponse,
    UpdateDeliveryFormRequest,
    UpdateDeliveryFormResponse,
} from 'types';

const baseUrl: string = '/travel-service';
export const apiGetTravelService = (params: GetTravelServiceRequestType) => {
    return Api<GetTravelServiceResponseType[]>(Method.GET, baseUrl + '/travel-service', params, null);
};
export const apiCalculatePackDiscount = (body: CalculatePackDiscountRequestType) => {
    return Api<CalculatePackDiscountResponseType>(Method.POST, baseUrl + '/pack-discount/discount', null, body);
};
export const apiCreateTravelPackProduct = (body: CreateTravelPackProductRequest, token: string | null) => {
    return Api<CreateTravelPackProductResponse>(Method.POST, baseUrl + '/travel-pack/product', null, body, undefined, token);
};
export const apiGetTravelPackProduct = (id: number | string | string[], token: string | null) => {
    return Api<GetTravelPackProductResponse>(Method.GET, baseUrl + `/travel-pack/submission/${id}`, null, null, undefined, token);
};
export const apiRequestDataTravelPackForm = (id: number | string | string[], body: RequestDataTravelPackFormRequest, token: string | null) => {
    return Api<RequestDataTravelPackFormResponse>(Method.PATCH, baseUrl + `/travel-pack/item/${id}/request-data`, null, body, undefined, token);
};
export const apiUpdateDeliveryForm = (id: number | string | string[], body: UpdateDeliveryFormRequest, token: string | null) => {
    return Api<UpdateDeliveryFormResponse>(Method.PATCH, baseUrl + `/travel-pack/${id}/delivery`, null, body, undefined, token);
};
export const apiGetTravelPack = (params: GetTravelPackOrTopUpRequestType, token: string | null) => {
    return Api<Array<GetTravelPackResponseType>>(Method.GET, '/travel-service/travel-pack/', params, null, undefined, token);
};
export const apiGetTravelPackInfoById = (id: number, token: string | null) => {
    return Api<GetTravelPackInfoResponseType>(Method.GET, `/travel-service/travel-pack/${id}`, null, null, undefined, token);
};
export const apiDocUpload = (body: FormData, token: string | null, cancelToken: CancelToken) => {
    return Api<DocUploadResponse>(Method.POST, '/authentication/passenger/document/upload', null, body, cancelToken, token);
};
export const apiGetDebitCardTopUp = (params: GetTravelPackOrTopUpRequestType, token: string | null) => {
    return Api<Array<GetDebitCardTopUpResponseType>>(Method.GET, '/travel-service/debit-card/top-up/', params, null, undefined, token);
};
