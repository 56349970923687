import { Box, Button } from '@mui/material';
import type { ButtonSliderType } from './carousel.types';

// MUI STYLE
const buttonNavigation = {
  p: {
    xs: 1,
    md: 1.5,
  },
  width: {
    xs: '24px',
    md: '48px',
  },
  height: {
    xs: '24px',
    md: '48px',
  },
  minWidth: 0,
  opacity: 0.8,
  borderRadius: '50px',
  '& .MuiButton-endIcon': {
    m: 0,
  },
  backgroundColor: 'white',
  color: 'black',
  '&:hover': {
    backgroundColor: 'white',
    opacity: 1,
  },
};

export const CarouselButton: React.FC<ButtonSliderType> = ({ icon, navigation, swiperRef }) => (
  <Button
    size='large'
    variant='contained'
    sx={buttonNavigation}
    onClick={() => {
      navigation === 'prev' ? swiperRef?.current?.prev() : swiperRef?.current?.next();
    }}>
    <Box
      fontSize={{
        xs: 12,
        md: 28,
      }}
      component='span'
      className={icon}></Box>
  </Button>
);
