import { Box, type SxProps } from '@mui/material';
import React from 'react';
import { CarouselButton } from './carousel-button';
import { SwiperSlide } from 'swiper/react';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import SwiperSlider from './swiper-slider/swiper-slider';
import { SwiperSliderProps } from './swiper-slider/swiper-slider.types';

const buttonStyle: SxProps = {
    position: 'absolute',
    top: '30%',
    zIndex: 100,
};

export const Carousel: React.FC<
    {
        children: React.ReactNode[];
        buttonBlockCenter?: boolean;
        sliderStyle?: Record<string, string>;
    } & Pick<SwiperSliderProps, 'breakpoints' | 'loop'>
> = ({ children, buttonBlockCenter = false, sliderStyle, ...rest }) => {
    // Reference
    const swiperRef = React.useRef<SwiperButtonRef>(null);
    const language = useLanguage();
    const rightButton = React.useRef<HTMLDivElement>(null);
    const leftButton = React.useRef<HTMLDivElement>(null);

    // Change display of buttons if it has to change on hover
    const changeButtonDisplay = (display: 'none' | 'block') => {
        if (rightButton.current) rightButton.current.style.display = display;
        if (leftButton.current) leftButton.current.style.display = display;
    };

    return (
        <Box
            position='relative'
            onMouseLeave={() => changeButtonDisplay('none')}
            onMouseEnter={() => changeButtonDisplay('block')}>
            <Box
                ref={rightButton}
                sx={{
                    ...buttonStyle,
                    ...(buttonBlockCenter && { transform: `translate(0px, -50%)`, top: '50%' }),
                    left: 8,
                    display: 'none',
                }}>
                <CarouselButton
                    icon={language.direction === 'ltr' ? 'icon-chevron-light-left' : 'icon-chevron-light-right'}
                    navigation='prev'
                    swiperRef={swiperRef}
                />
            </Box>
            <Box
                ref={leftButton}
                sx={{
                    ...buttonStyle,
                    ...(buttonBlockCenter && { transform: `translate(0px, -50%)`, top: '50%' }),
                    right: 8,
                    display: 'none',
                }}>
                <CarouselButton
                    icon={language.direction === 'ltr' ? 'icon-chevron-light-right' : 'icon-chevron-light-left'}
                    navigation='next'
                    swiperRef={swiperRef}
                />
            </Box>
            <Box px={{ md: 0 }}>
                <SwiperSlider
                    isPrevNext={false}
                    ref={swiperRef}
                    {...rest}>
                    {children.map((child, index) => (
                        <SwiperSlide
                            key={index}
                            style={{
                                ...sliderStyle,
                                height: 'auto',
                                paddingBottom: '2px',
                            }}>
                            {child}
                        </SwiperSlide>
                    ))}
                </SwiperSlider>
            </Box>
        </Box>
    );
};
