import { ApiTour, Method } from '@travel-platform/commons/src/services/api';
import { GetResponseForSitemap } from './tours.types';

export const apiGetTravelStyleSitemap = (version = '/') => {
    return ApiTour<GetResponseForSitemap>(Method.GET, `${version}travelStyles?sitemap=true`, null, null);
};

export const apiGetToursSitemap = (version = '/') => {
    return ApiTour<GetResponseForSitemap>(Method.GET, `${version}search?sitemap=true`, null, null);
};
