import { PassengersInformationType, SetValueVariantInput } from '@travel-platform/commons/src/types';

export enum ServiceVariantDiscount {
    PERCENTAGE = 'percentage',
    AMOUNT = 'amount',
}

export enum ShoppingCardProductNameEnum {
    SERVICE = 'SERVICE',
    FLIGHT = 'FLIGHT',
    HOTEL = 'HOTEL',
}

export enum TravelServiceInputType {
    SELECT_BOX = 'SELECT_BOX',
    NUMBER_INPUT = 'NUMBER_INPUT',
}
export type TravelServiceVariantFieldItemValuesType = {
    valueName: string;
    valueKey: string;
};
export type GetTravelServiceRequestType = {
    componentOptions?: boolean;
    travelServiceVariants?: boolean;
    /**
     * @deprecated use variantFields field instead
     */
    variantFieldNames?: boolean;
    formRequiredFields?: boolean;
    locale?: string;
    targetCountryCode?: string;
    isPrimary?: boolean;
    name?: string;
};
export type TravelServiceVariantsItems = {
    id: number;
    name?: string;
    price: number;
    description?: string;
    title?: string;
    imageUrl?: string | null;
    travelServiceId?: number;
    variantAttributes: Record<string, TravelServiceVariantFieldItemValuesType> | null;
};
export type TravelServiceVariantFieldItemType = {
    key: string;
    name: string;
    type: TravelServiceInputType;
    description: string;
    unit: string;
    unitName: string;
    unitPrice: string;
};

export type GetTravelServiceResponseType = {
    baseDiscountPercentage?: number;
    componentOptions: {
        icon: string;
        isFeatured: boolean;
        hideTittle: boolean;
        hideDescription: boolean;
        hideBasePrice: boolean;
        hideBaseDiscount: boolean;
        hideMoreInfo: boolean;
        isNew: boolean;
    };
    description: string;
    id: number;
    name: string;
    title: string;
    travelServiceVariants?: TravelServiceVariantsItems[];
    formRequiredFields?: string[];
    variantFields: TravelServiceVariantFieldItemType[];
};
export type ServiceVariantAmountType = {
    travelServiceVariantId: number;
    requestData: any;
    numberInputValues: Record<string, number>;
};
export type CalculatePackDiscountRequestType = {
    items: ServiceVariantAmountType[];
};
export type CalculatePackDiscountResponseType = {
    discount: number;
    type: ServiceVariantDiscount;
    totalPrice: number;
};
export type CreateTravelPackProductItemRequest = {
    travelServiceVariantId: number;
    quantity?: number;
    requestData?: Record<string, any>;
};
export type CreateTravelPackProductRequest = {
    items: CreateTravelPackProductItemRequest[];
};
export type CreateTravelPackProductResponse = {
    id: number;
    totalPrice: number;
    status: string;
};
export type GetTravelPackProductItem = {
    travelServiceTitle: string;
    travelPackItemId: number;
    travelServiceName: string;
    variantFieldNames: string[];
    meta: Record<string, any>;
    travelServiceVariantName: string;
    requestData: Record<string, any>;
    isRequestDataSubmitted: boolean;
    allowEdit: boolean;
    price: number;
    componentOptions: {
        isFeatured: boolean;
        hideTittle: boolean;
        hideDescription: boolean;
        hideBasePrice: boolean;
        hideBaseDiscount: boolean;
        hideMoreInfo: boolean;
        icon: string;
    };
};

export type GetTravelPackProductResponse = {
    id: number;
    userId: number;
    discount: CalculatePackDiscountResponseType;
    totalPrice: number;
    travelPackItems: GetTravelPackProductItem[];
    isDeliveryRequired: boolean;
    deliverAt: string;
    deliveryAddress: string;
};
export type RequestDataTravelPackFormRequest = Partial<PassengersInformationType>;
export type RequestDataTravelPackFormResponse = {
    id: string;
    travelPackId: string;
    travelServiceVariantId: number;
    price: string;
    requestData: {
        items: string[];
    };
    isRequestDataSubmitted: boolean;
};
export type UpdateDeliveryFormRequest = {
    deliverAt: string;
    deliveryAddress: string;
};
export type UpdateDeliveryFormResponse = {
    deliveryAddress: string;
    discount: number;
    id: string;
    status: string;
    totalPrice: string;
    updatedAt: string;
    userId: string;
};

export enum VariantsType {
    Number = 'Number',
    Select = 'Select',
}

export type VariantValue = number | string | Record<string, string>;
export type ServiceVariant = {
    id: number;
    productName: ShoppingCardProductNameEnum.SERVICE;
    quantity: number;
    travelServiceId: number;
    type: VariantsType;
    additionalData: TravelServiceVariantsItems | null;
} & SetValueVariantInput;
export type Variant = ServiceVariant;
export type Variants = {
    serviceName: string;
    serviceTitle: string;
    items: Variant[];
};
