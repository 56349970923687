import React from 'react';
import { useRouter } from 'next/router';
import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import palette from 'components/common/style/color.module.scss';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';

// Mui Style
const serviceCardStyle: SxProps<Theme> = {
    position: 'relative',
    cursor: 'pointer',
    pb: 2,
};

export type LandingServiceModuleCardHeaderItemProps = {
    id: number;
    slug: string;
    icon: string;
    title: string;
    isActive: boolean;
    isNew?: boolean;
};

export const ServiceModuleHeaderDesktopItem: React.FC<LandingServiceModuleCardHeaderItemProps> = props => {
    // Destructure Props
    const { title, isActive, slug, icon, isNew } = props;
    const languageJson = useLanguage();
    // Use Hooks
    const router = useRouter();

    return (
        <Stack
            position='relative'
            onClick={() => router.push(`${slug}`)}
            sx={serviceCardStyle}>
            {isActive && (
                <Box
                    borderRadius='2px'
                    position='absolute'
                    bottom={0}
                    right='50%'
                    width='100%'
                    border={`4px solid ${palette.primary}`}
                    sx={{
                        transform: 'translateX(50%)',
                    }}></Box>
            )}

            <Stack
                id='LandingServiceModuleCardHeaderItem'
                direction='row'
                alignItems='center'
                width='100%'
                spacing={1}>
                {isNew && (
                    <Stack
                        position={'absolute'}
                        sx={{
                            backgroundColor: palette.secondary,
                        }}
                        borderRadius={2}
                        p={0.5}
                        left={'-8px'}
                        top={'-5px'}
                        color={palette.white}>
                        <Typography variant='c3_bold'>{languageJson.common.new}</Typography>
                    </Stack>
                )}
                <Box
                    color={isActive ? palette.primary : 'black'}
                    fontSize={18}
                    className={icon}></Box>

                <Typography
                    sx={{
                        typography: {
                            xs: 'c2_regular',
                            md: 't3_bold',
                        },
                    }}
                    color={isActive ? 'primary' : ''}>
                    {title}
                </Typography>
            </Stack>
        </Stack>
    );
};
