import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { PassengersInformationType, SetValueVariantInput, VariantField } from '@travel-platform/commons/src/types/travel-pack';

export enum ServiceVariantDiscount {
    PERCENTAGE = 'percentage',
    AMOUNT = 'amount',
}

export enum ShoppingCardProductNameEnum {
    SERVICE = 'SERVICE',
    FLIGHT = 'FLIGHT',
    HOTEL = 'HOTEL',
}
export enum EnumMediaType {
    PASSPORT_PHOTO = 'PASSPORT_PHOTO',
    PERSONAL_PHOTO = 'PERSONAL_PHOTO',
}
export type GetTravelServiceRequestType = {
    componentOptions?: boolean;
    travelServiceVariants?: boolean;
    /**
     * @deprecated
     */
    variantFieldNames?: boolean;
    formRequiredFields?: boolean;
    locale?: string;
    targetCountryCode?: string;
};

export type TravelServiceVariantsItems = {
    id: number;
    name?: string;
    price: string;
    description?: string;
    travelServiceId?: number;
    meta?: Record<string, any>;
};

export type GetTravelServiceResponseType = {
    id: number;
    name: string;
    title: string;
    description: string;
    componentOptions: {
        icon: string;
        isFeatured: boolean;
        hideTittle: boolean;
        hideDescription: boolean;
        hideBasePrice: boolean;
        hideBaseDiscount: boolean;
        hideMoreInfo: boolean;
    };
    basePrice: string;
    baseDiscountPercentage?: number;
    /**
     * @deprecated use variantFields field instead
     */
    variantFieldNames?: string[];
    variantFields: VariantField[];
    formRequiredFields?: string[];
    travelServiceVariants?: TravelServiceVariantsItems[];
};
export type ServiceVariantAmountType = {
    serviceVariant: number;
    quantity?: number;
    requestData: Record<string, any>;
};
export type CalculatePackDiscountRequestType = {
    serviceVariantAmount: ServiceVariantAmountType[];
};
export type CalculatePackDiscountResponseType = {
    discount: number;
    type: ServiceVariantDiscount;
    totalPrice: number;
};
export type CreateTravelPackProductItemRequest = {
    travelServiceVariantId: number;
    quantity?: number;
    requestData?: Record<string, any>;
};
export type CreateTravelPackProductRequest = {
    items: CreateTravelPackProductItemRequest[];
};
export type CreateTravelPackProductResponse = {
    id: number;
    totalPrice: number;
    status: string;
};
export type GetTravelPackProductItem = {
    travelServiceTitle: string;
    travelPackItemId: number;
    travelServiceName: string;
    variantFieldNames: string[];
    variantFields: VariantField[];
    meta: Record<string, any>;
    travelServiceVariantName: string;
    requestData: Record<string, any>;
    isRequestDataSubmitted: boolean;
    allowEdit: boolean;
    price: number;
    componentOptions: {
        isFeatured: boolean;
        hideTittle: boolean;
        hideDescription: boolean;
        hideBasePrice: boolean;
        hideBaseDiscount: boolean;
        hideMoreInfo: boolean;
        icon: string;
    };
};

export type GetTravelPackProductResponse = {
    id: number;
    userId: number;
    discount: CalculatePackDiscountResponseType;
    totalPrice: number;
    travelPackItems: GetTravelPackProductItem[];
    isDeliveryRequired: boolean;
    deliverAt: string;
    deliveryAddress: string;
};
export type RequestDataTravelPackFormRequest = Partial<PassengersInformationType>;
export type RequestDataTravelPackFormResponse = {
    id: string;
    travelPackId: string;
    travelServiceVariantId: number;
    price: string;
    requestData: {
        items: string[];
    };
    isRequestDataSubmitted: boolean;
};
export type UpdateDeliveryFormRequest = {
    deliverAt: string;
    deliveryAddress: string;
};
export type UpdateDeliveryFormResponse = {
    deliveryAddress: string;
    discount: number;
    id: string;
    status: string;
    totalPrice: string;
    updatedAt: string;
    userId: string;
};

export enum VariantsType {
    Number = 'Number',
    Select = 'Select',
}

export type VariantValue = number | string | Record<string, string>;
export type ServiceVariant = {
    id: number;
    productName: ShoppingCardProductNameEnum.SERVICE;
    quantity: number;
    travelServiceId: number;
    type: VariantsType;
    additionalData: TravelServiceVariantsItems | null;
} & SetValueVariantInput;
export type Variant = ServiceVariant;
export type Variants = {
    serviceName: string;
    serviceTitle: string;
    items: Variant[];
};
export type TravelPackItemsType = {
    id: string;
    travelPackId: string;
    travelServiceVariantId: number;
    price: string;
    requestData: object;
    isRequestDataSubmitted: boolean;
    travelServiceVariant: {
        id: number;
        name: string;
        price: string;
        description: string;
        travelServiceId: number;
        meta: object;
    };
};
export type GetTravelPackResponseType = {
    id: string;
    userId: string;
    discount: number;
    totalPrice: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    deliverAt: string | null;
    travelPackItems: TravelPackItemsType[];
};
export type GetDebitCardTopUpResponseType = {
    amount: string;
    createdAt: string;
    debitCard: {
        cardNumber: string;
        cvv2: string | null;
        expireIn: string | null;
        id: number;
        userId: number;
    };
    debitCardId: number;
    id: number;
    status: string;
};
export type GetTravelPackOrTopUpRequestType = {
    ids: string[];
};

export enum TRAVEL_PACK_STATUS {
    CREATED,
    USER_ORDERED,
    USER_SUBMITTED,
    ADMIN_VERIFIED,
    COMPLETED,
    DELIVERED,
}
export type GetTravelPackInfoResponseType = {
    id: string;
    userId: string;
    discount: 0;
    totalPrice: string;
    status: TRAVEL_PACK_STATUS;
    createdAt: Date;
    updatedAt: Date;
    deliverAt?: Date;
    deliveryAddress?: string;
    travelPackItems: TravelPackItemType[];
};

export type TravelPackItemType = {
    id: string;
    travelPackId: string;
    travelServiceVariantId: number;
    price: string;
    requestData: Record<any, any>;
    isRequestDataSubmitted: boolean;
    numberInputValues: Record<string, number>;
    travelServiceVariant: {
        id: number;
        name: string;
        price: string;
        travelServiceId: number;
        imageUrl?: string;
        travelService: {
            id: number;
            name: string;
            priority: number;
            isPrimary: boolean;
            componentOptions: {
                icon: string;
                hideTittle: boolean;
                isFeatured: boolean;
                hideMoreInfo: boolean;
                hideBasePrice: boolean;
                hideDescription: boolean;
                hideBaseDiscount: boolean;
            };
            createdAt: Date;
            updatedAt: Date;
            isDeliveryRequired: boolean;
            storeId: number;
            travelServiceRequestDataFormId: number;
            targetCountryCode: string;
            travelServiceRequestDataForm: {
                id: number;
                jsonSchema: RJSFSchema;
                uiSchema: UiSchema;
            };
        };
    };
};
export type DocUploadResponse = {
    id: number;
    fileName: string;
    type: EnumMediaType;
    passengerId: number;
    url: string;
};
