import { Divider, Rating, Stack, Typography } from '@mui/material';
import palette from 'components/common/style/color.module.scss';
import { useRouter } from 'next/router';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import HotelReviewSore from 'components/hotels/hotel-review-score/hotel-review-score';
import NextImage from '@travel-platform/commons/src/components/common/next-image';
import Price from '../price';

export type CardItemType = {
    link: string;
    upTitle?: string;
    title: string;
    rating?: number;
    basePrice?: number;
    discount?: number;
    reviewScore?: number;
    image?: string;
    scaleOfPrice?: string;
    [key: string]: any;
};
const VOICard = (prop: CardItemType) => {
    const { link, image, upTitle, title, rating, basePrice, discount, reviewScore, scaleOfPrice } = prop;
    const router = useRouter();
    const languageJson = useLanguage();

    return (
        <Stack
            sx={{ border: `1px solid ${palette.gray_light_40}`, borderRadius: '8px', cursor: 'pointer' }}
            onClick={() => {
                router.push(link);
            }}>
            <NextImage
                width={0}
                height={0}
                sizes='100vw'
                alt={`${upTitle} pic`}
                style={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: '260px',
                    minHeight: '260px',
                    borderRadius: '8px 8px 0 0',
                }}
                src={image}></NextImage>

            <Stack p={2}>
                {upTitle && <Typography sx={{ typography: { xs: 'c2_regular', md: 'b1_regular' } }}>{upTitle}</Typography>}
                {title && (
                    <Typography
                        component={'h3'}
                        sx={{
                            typography: { xs: 'b1_bold', md: 't3_bold' },
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            WebkitBoxOrient: 'vertical',
                            minHeight: 80,
                        }}>
                        {title}
                    </Typography>
                )}
                {rating && (
                    <Rating
                        sx={{ marginY: 1, fontSize: 16, color: palette.primary }}
                        readOnly
                        name='simple-controlled'
                        value={rating}
                        precision={0.5}
                        icon={<span className={'icon-star-filled '}></span>}
                        emptyIcon={<span className={'icon-star '}></span>}
                    />
                )}
                {reviewScore && <HotelReviewSore reviewScore={reviewScore} />}
                {!!basePrice && (
                    <>
                        <Divider sx={{ marginY: 2 }} />
                        <Stack>
                            <Price
                                boxStyle={{ justifyContent: 'end' }}
                                amountStyle={{
                                    typography: {
                                        xs: 't3_bold',
                                        md: 't2_bold',
                                    },
                                    textAlign: 'end',
                                }}
                                discountStyle={{
                                    typography: {
                                        xs: 'b2_regular',
                                        md: 't3_regular',
                                    },
                                    textAlign: 'end',
                                }}
                                amount={basePrice || 0}
                                discount={discount}
                                discountType={'percentage'}
                            />
                        </Stack>

                        <Typography
                            textAlign={'end'}
                            sx={{ typography: { xs: 'c1_regular', md: 'b2_regular' } }}>
                            {scaleOfPrice || languageJson.common.per_night}
                        </Typography>
                    </>
                )}
            </Stack>
        </Stack>
    );
};
export default VOICard;
