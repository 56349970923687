import { Box, Stack } from '@mui/material';
import React from 'react';
import palette from 'components/common/style/color.module.scss';
import { ServiceModuleHeaderDesktopItem } from './service-module-header-desktop';
import { GetTravelServiceResponseType } from 'types';
import { LandingServiceModuleCardVariant } from './service-module-box';
import useDevice, { DeviceEnum } from '@travel-platform/commons/src/hook/use-device';
import { useAppSelector } from 'redux/hooks';

type ServiceModuleProps = {
    serviceSelected: GetTravelServiceResponseType;
    services: GetTravelServiceResponseType[];
};

export const ServiceModule: React.FC<ServiceModuleProps> = ({ services, serviceSelected, ...rest }) => {
    // Constant
    const isMobile = useDevice() === DeviceEnum.MOBILE;
    const { landings } = useAppSelector(state => state.landings);
    return (
        <React.Fragment>
            {!isMobile && (
                <Stack
                    direction='row'
                    border={`1px solid ${palette.primary}`}
                    borderBottom={{
                        md: 'none',
                    }}
                    pt={2}
                    px={6}
                    pb={0}
                    borderRadius={{
                        xs: 4,
                        md: '16px 16px 0  0 ',
                    }}
                    sx={{
                        backgroundColor: palette.white,
                    }}
                    spacing={4}>
                    {services.slice(0, 4).map(({ id, title, componentOptions: { icon }, name, variantFields }) => {
                        if (variantFields.length < 1) return <React.Fragment />;
                        return (
                            <ServiceModuleHeaderDesktopItem
                                key={id}
                                id={id}
                                title={title}
                                icon={icon}
                                slug={landings.find(landing => landing.travel_service_id === id)?.slug!}
                                isActive={serviceSelected.name === name}
                                {...rest}
                            />
                        );
                    })}
                </Stack>
            )}

            <Stack
                position='relative'
                spacing={6}
                border={`1px solid ${palette.primary}`}
                pt={2.5}
                pb={2}
                px={4}
                borderRadius={{
                    xs: 4,
                    md: '0 0 16px 16px',
                }}
                sx={{
                    backgroundColor: palette.white,
                }}>
                {serviceSelected.variantFields.length > 0 && (
                    <Box>
                        <LandingServiceModuleCardVariant service={serviceSelected} />
                    </Box>
                )}
            </Stack>
        </React.Fragment>
    );
};
