import { Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import palette from 'components/common/style/color.module.scss';
import { TourPromotionType } from '@travel-platform/commons/src/services/tours';

type IPrice = {
    amount: number | string;
    amountStyle?: object;
    discount?: number;
    discountType?: TourPromotionType;
    discountStyle?: object;
    boxStyle?: object;
};

const Price: FC<IPrice> = ({ amount, amountStyle, discountType = 'percentage', discount = 0, discountStyle, boxStyle }: IPrice) => {
    // String to Number amount
    const amountNumber: number = Number(amount);

    // Calc Percentage
    let finalPrice = amountNumber;
    if (discountType === 'percentage') {
        finalPrice = Number(((amountNumber * (100 - discount)) / 100).toFixed(0).toLocaleString());
    } else {
        finalPrice = amountNumber - discount;
    }

    return (
        <Stack
            direction='row'
            alignItems='center'
            flexWrap='wrap'
            sx={{ ...boxStyle }}>
            {discount > 0 && amountNumber > 0 && (
                <Typography
                    sx={{
                        ...discountStyle,
                        fontWeight: '400 !important',
                        position: 'relative',
                        '&::after ': {
                            content: "''",
                            width: '100%',
                            height: '2px',
                            background: palette.error,
                            position: 'absolute',
                            bottom: {
                                xs: '11px',
                                md: '12px',
                            },
                            left: 0,
                            transform: {
                                xs: 'skewY(-13deg)',
                                md: 'skewY(-20deg)',
                            },
                        },
                    }}
                    mr={1.5}
                    variant='b2_regular'>
                    {`${amountNumber} $`}
                </Typography>
            )}
            <Typography
                sx={{
                    typography: {
                        xs: 'b2_bold',
                        md: 'b1_bold',
                    },
                    ...amountStyle,
                }}>
                {discount > 0 ? finalPrice : amountNumber} $
            </Typography>
        </Stack>
    );
};

export default Price;
