import { useEffect, useState } from 'react';
import CardSlider from '../card-slider';
import type { CardItemType } from '../voi-card';
import { useRouter } from 'next/router';
import { name_to_url } from '../functions/name-to-url';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import { apiGetHotelInfoList } from 'services';
import { wrapperCardSliderType } from 'types';

const SliderWrapper = (props: wrapperCardSliderType) => {
    const { queryParams, type } = props;

    const [items, setItems] = useState<CardItemType[]>();
    const router = useRouter();
    const languageJson = useLanguage();

    useEffect(() => {
        (async () => {
            if (type === 'Hotel') {
                let params: any = {
                    page: 1,
                    perPage: 10,
                };
                if (queryParams) {
                    params = { ...params, ...queryParams };
                }
                const hotelInfoListResponse = await apiGetHotelInfoList(params);
                setItems(
                    hotelInfoListResponse.data.result.map(hotel => ({
                        ...hotel,
                        link: `/${router.locale}/hotel/${hotel.cityName}/${hotel.hotelId}/${name_to_url(hotel.name)}`,
                        upTitle: hotel?.cityName,
                        title: hotel.name,
                        rating: hotel?.rating,
                        basePrice: hotel.defaultStartPrice,
                        discount: hotel.defaultShowingDiscountPtc ? hotel.defaultShowingDiscountPtc : 0,
                        reviewScore: hotel.reviewScore,
                        image: hotel.images ? hotel.images[0]?.link : undefined,
                        scaleOfPrice: languageJson.common.per_night,
                    }))
                );
            }
        })();
    }, [router.locale]);

    return (
        <>
            {items && (
                <CardSlider
                    {...props}
                    items={items}></CardSlider>
            )}
        </>
    );
};
export default SliderWrapper;
