import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import type { OverridableStringUnion } from '@mui/types';
import Link from 'next/link';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';

type TravelPackContinueButtonProps = {
    size?: OverridableStringUnion<'small' | 'medium' | 'large'>;
    href: string;
};
export const TravelPackContinueButton: React.FC<TravelPackContinueButtonProps> = ({ size, href }) => {
    // Use Hooks
    const languageJson = useLanguage();

    return (
        <Box>
            <Link href={href}>
                <Button
                    fullWidth
                    color='primary'
                    size='large'
                    variant='contained'>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        spacing={{
                            xs: 0.5,
                            md: '6px',
                        }}>
                        <Typography variant={size === 'small' ? 'c1_bold' : 't3_bold'}>{languageJson.common.continue}</Typography>
                        <Box
                            component='span'
                            className={languageJson.direction === 'ltr' ? 'icon-chevron-light-right' : 'icon-chevron-light-left'}
                            fontSize={size === 'small' ? 16 : 28}></Box>
                    </Stack>
                </Button>
            </Link>
        </Box>
    );
};
