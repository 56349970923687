import { Box, Typography } from '@mui/material';
import styles from './hotel-review-score.module.scss';
import palette from 'components/common/style/color.module.scss';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';

const HotelReviewSore = ({ reviewScore }: { reviewScore: number }) => {
    const languageJson = useLanguage();

    const scoreInfo = {
        color: '',
        title: '',
    };

    if (reviewScore >= 9 || 10 === reviewScore) {
        scoreInfo.color = palette.success_darker_40;
        scoreInfo.title = languageJson.common.excellent;
    } else if (reviewScore >= 8 || 9 < reviewScore) {
        scoreInfo.color = palette.success_darker_20;
        scoreInfo.title = languageJson.common.very_good;
    } else if (reviewScore >= 6 || 8 < reviewScore) {
        scoreInfo.color = palette.success;
        scoreInfo.title = languageJson.common.good;
    } else if (reviewScore >= 5 || 6 < reviewScore) {
        scoreInfo.color = palette.warning;
        scoreInfo.title = languageJson.common.average;
    } else if (reviewScore >= 3 || 5 < reviewScore) {
        scoreInfo.color = palette.error;
        scoreInfo.title = languageJson.common.poor;
    } else if (reviewScore >= 0 || 3 < reviewScore) {
        scoreInfo.color = palette.error_darker_20;
        scoreInfo.title = languageJson.common.not_recomended;
    }

    return (
        <Typography className={styles.reviewScore}>
            <Box
                className={styles.score}
                sx={{ backgroundColor: scoreInfo.color }}>
                {reviewScore}
            </Box>
            <Box
                className={styles.text}
                color={scoreInfo.color}>
                {scoreInfo.title}
            </Box>
        </Typography>
    );
};

export default HotelReviewSore;
