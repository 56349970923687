import type { FC } from 'react';
import JsonLd from './JsonLd';
import { CmsLandings } from '@travel-platform/commons/src/types';

const JsonLdPage: FC<{ landingData: CmsLandings }> = ({ landingData }) => {
    const { slug, title, description, thumbnail } = landingData;

    const isService = slug !== 'flight' && slug !== 'hotels' && slug !== 'tours';

    const jsonLd = {
        '@context': 'https://schema.org',
        '@type': isService ? 'Service' : 'WebPage',
        ...(isService && { serviceType: title }),
        name: title,
        image: thumbnail?.src,
        description: description,
        aggregateRating: {
            '@type': 'AggregateRating',
            bestRating: '5',
            ratingValue: '5',
            reviewCount: '76',
        },
    };

    return <JsonLd data={jsonLd} />;
};
export default JsonLdPage;
