import { ApiCMS, Locale, Method } from '@travel-platform/commons/src/services/api';
import { CmsGetCountriesResponse, CmsGetLandingDetailResponse } from 'types';

const queris: Record<string, string> = {
    'populate[0]': 'components.table',
    'populate[1]': 'components.about',
    'populate[2]': 'components.attention_box.landing_component_attention_box_items',
    'populate[3]': 'components.alert',
    'populate[4]': 'components.plan',
    'populate[5]': 'components.faqs',
    'populate[6]': 'thumbnail',
    'populate[7]': 'seo',
    'populate[8]': 'components.step.landing_step_items',
    'populate[9]': 'components.travel_service_plan.landing_travel_service_plan_items',
    'populate[10]': 'components.plan_select_box.plan_select_box_items',
    'populate[11]': 'components.top_activity.items',
    'populate[12]': 'components.top_attraction.items',
    'populate[13]': 'components.country_list.items',
    'populate[14]': 'components.marketing_banner.items',
};

let populateComponent: string = '?';

Object.entries(queris).forEach(([key, value], index) => {
    populateComponent += `${key}=${value}&`;
});

export const apiCmsGetLandingList = (locale: null | string | Locale, populate = true) => {
    return ApiCMS<CmsGetLandingDetailResponse>(Method.GET, `/landings${populate ? populateComponent : ''}`, null, null, locale);
};

export const apiCmsGetLandingDataBySlug = (slug: string, locale: null | string | Locale) => {
    return ApiCMS<CmsGetLandingDetailResponse>(Method.GET, `/landings${populateComponent}&filters[slug][${slug ? '$eq' : '$null'}]=${slug ? slug : null}`, null, null, locale);
};

export const apiCmsGetCountries = (locale: null | string | Locale) => {
    return ApiCMS<CmsGetCountriesResponse>(Method.GET, '/countries', null, null, locale);
};
