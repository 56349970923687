import { Button, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import type { CardItemType } from '../voi-card';
import VOICard from '../voi-card';
import { Carousel } from '../carousel';
import { useRouter } from 'next/router';

export type CardSliderType = {
    title: string;
    subTitle?: string;
    categorizeProperty?: string;
    slidesPerView?: number;
    items: CardItemType[];
};

const CardSlider = (props: CardSliderType) => {
    const { title, subTitle, categorizeProperty, slidesPerView, items } = props;

    const [categorizeList, setCategorizeList] = useState<string[]>();
    const [selectedCategory, setSelectedCategory] = useState<string>('');

    const router = useRouter();

    useEffect(() => {
        if (categorizeProperty) {
            const list = Array.from(new Set(items?.map(item => item[categorizeProperty as keyof typeof item])));
            setCategorizeList(['All', ...(list as string[])]);
            setSelectedCategory('All');
        }
    }, [items, router.locale]);

    const categorizedItems = useMemo(() => (!categorizeProperty ? items : selectedCategory === 'All' ? items : items.filter(item => item[categorizeProperty as keyof typeof item] === selectedCategory)), [selectedCategory, items]);

    return (
        <>
            <Stack>
                {title && (
                    <Typography
                        sx={{
                            typography: {
                                xs: 't1_bold',
                                md: 'h3_bold',
                            },
                        }}
                    >
                        {title}
                    </Typography>
                )}
                {subTitle && (
                    <Typography
                        mb={1}
                        sx={{
                            typography: {
                                xs: 't3_regular',
                                md: 't2_regular',
                            },
                        }}
                    >
                        {subTitle}
                    </Typography>
                )}
                {categorizeProperty && (
                    <Stack
                        mb={2}
                        direction='row'
                        sx={{
                            overflowX: 'scroll',
                            '&::-webkit-scrollbar': {
                                width: 0,
                                height: 0,
                            },
                        }}
                        spacing={1}
                    >
                        {categorizeList?.map(category => (
                            <Button sx={{ paddingX: 1, flex: '0 0 auto', minWidth: '70px' }} variant={category === selectedCategory ? 'contained' : 'outlined'} onClick={() => setSelectedCategory(category)} key={category}>
                                {category}
                            </Button>
                        ))}
                    </Stack>
                )}
            </Stack>
            <Carousel
                loop={false}
                breakpoints={{
                    0: {
                        slidesPerView: 1.5,
                        spaceBetween: 16,
                    },
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 16,
                    },
                    1200: {
                        slidesPerView: slidesPerView || 4,
                        spaceBetween: 16,
                    },
                }}
            >
                {categorizedItems.map(hotel => (
                    <VOICard key={hotel.title} {...hotel} />
                ))}
            </Carousel>
        </>
    );
};
export default CardSlider;
